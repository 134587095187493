<template>
  <div id="data-list-list-view">
   
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Rates')">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="activePrompt=true"
          v-if=" $acl.check('Expert')"
          >{{ $t("AddNew") }}</vs-button
        >
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="VideoModel.VideoRates"
        >
          <template slot="thead">
            <vs-th> {{ $t("User") }}</vs-th>
            <vs-th> {{ $t("Expert") }}</vs-th>
            <vs-th> {{ $t("Rate") }}</vs-th>
     
            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              
                <vs-td >
                  <p v-if="tr.AppUser" class="product-name font-medium truncate">
                    {{ tr.AppUser.ApplicationUser.FullName }}
                  </p>
                </vs-td>
                <vs-td >
                  <p v-if="tr.Expert" class="product-name font-medium truncate">
                    {{ tr.Expert.ApplicationUser.FullName }}
                  </p>
                </vs-td>
                <vs-td>
                  <star-rating
                  v-if="tr.AppUser"
                  v-model="tr.UserRate"
                  v-bind:star-size="10"
                  v-bind:show-rating="false"
                  v-bind:max-rating="10"
                  read-only="true"
                ></star-rating>
                <star-rating
                 v-else
                  v-model="tr.ExpertRate"
                  v-bind:star-size="10"
                  v-bind:show-rating="false"
                  v-bind:max-rating="10"
                  read-only="true"
                ></star-rating>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <vs-button
                  v-if="((tr.ExpertID == activeUserInfo.ExpertID && (tr.ExpertID!=0||tr.ExpertID!=null||tr.ExpertID!=undefined))|| $acl.check('Admin'))"
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <vs-popup  :active.sync="activePrompt">
              <vs-card>
                <star-rating
                  v-model="rateModel.ExpertRate"
                  v-bind:star-size="50"
                  v-bind:show-rating="false"
                  v-bind:max-rating="10"
                ></star-rating>
      <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-center">
          <vs-button :disabled="!(rateModel.ExpertRate)" type="border" color="success" @click="AddRate">{{
            $t("Add")
          }}</vs-button>
        </div>
      </div>
    </div>

                </vs-card>
       </vs-popup>

  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating
  },
  props: {
    VideoModel: {
      type: Object,
      required: true
    }
  },
  computed:{
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  data() {
    return {
      rateModel:{},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      isMounted: false,
      rowDataForDelete: {},
      VideoRoomModel: {},
      activePrompt: false,
   //   UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {
    AddRate(){
      var data ={};
      data.VideoID = this.VideoModel.ID;
      data.ExpertID = this.activeUserInfo.ExpertID;
      data.ExpertRate = this.rateModel.ExpertRate;
      this.$store.dispatch("VideoList/AddVideoRate", data).then(res=>{
        this.VideoModel.VideoRates = res.data.data;
        this.activePrompt =  false;
        this.rateModel = {};
        window.showSuccess();
      })
    },
    
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },

    acceptAlert() {
      this.deleteVideoRate(this.rowDataForDelete);

      window.showDeleteSuccess();
    },
    deleteVideoRate(data) {
      if (data.ID > 0) {
        this.$store.dispatch("VideoList/DeleteVideoRate", data).then(()=>{
          const ItemIndex = this.VideoModel.VideoRates.findIndex((p) => p.ID == data.ID)
          this.VideoModel.VideoRates.splice(ItemIndex, 1)
        });
      }      
    }
  },
  created() {
   
  },
 
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
