<template>
  <div>
    <div class="vx-row">
   
      <video
      v-if="VideoModel.URL"
              :width="1200"
              :height="300"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + VideoModel.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>
            
      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("DescriptionEn") }}</label>
         <quill-editor v-model="VideoModel.DescriptionEn" :options="editorOption" />
      
      </div>
       <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("DescriptionAr") }}</label>
         <quill-editor v-model="VideoModel.DescriptionAr" :options="editorOption" />
    
      </div>

      <div class="vx-col lg:w-1/2 w-full mt-2">
   
      </div>
    </div>
    <div class="vx-row mt-5" >
      <div class="vx-col lg:w-1/5 w-full">
        <label class="vs-input--label">{{ $t("TotalRate") }}</label>
        <vs-input
          v-model="VideoModel.TotalRateValue"
          class="w-full"
          disabled=""
          name="AccountNumber"
        />
      </div>
      <div class="vx-col lg:w-1/5 w-full">
        <label class="vs-input--label">{{ $t("RateUsers") }}</label>
        <star-rating
                  v-model="VideoModel.RateCount"
                  v-bind:star-size="20"
                  v-bind:show-rating="false"
                  v-bind:max-rating="10"
                  v-bind:read-only="true"
                ></star-rating>
      </div>
      <div class="vx-col lg:w-1/5 w-full">
        <label class="vs-input--label">{{ $t("LiksUsers") }}</label>
        <vs-input
          v-model="VideoModel.LikeCount"
          class="w-full"
          disabled=""
          name="AccountNumber"
        />
      </div>
      
      <div class="vx-col lg:w-1/5 w-full">
        <label class="vs-input--label">{{ $t("ViewCount") }}</label>
        <vs-input
          v-model="VideoModel.ViewCount"
          class="w-full"
          disabled=""
          name="AccountNumber"
        />
      </div>

      <div class="vx-col lg:w-1/5 w-full">
        <label class="vs-input--label">{{ $t("Comments") }}</label>
        <vs-input
          v-model="VideoModel.CommentCount"
          class="w-full"
          disabled=""
          name="AccountNumber"
        />
      </div>

    </div>
    

    <div class="vx-row"></div>
  </div>
</template>
<script>
// import Datepicker from "vuejs-datepicker";
import StarRating from "vue-star-rating";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { domain } from "@/gloabelConstant.js";

export default {
  components: {
    // Datepicker,
    StarRating,
    quillEditor
  },
  props: {
    VideoModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    
  },
  data() {
    return {
         editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': [] }],
          ],
        },
        placeholder: 'Message'
      },
      isMounted: false,
       baseURL: domain,
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {
    
  },
  created() {
   
  },
};
</script>
<style></style>
