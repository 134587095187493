<template>
  <div>
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.push('/Video')"
    />
    <vs-alert
      color="danger"
      title="Course Not Found"
      :active.sync="Video_not_found"
    >
      <span>Video record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'Video' }" class="text-inherit underline"
          >All Video</router-link
        >
      </span>
    </vs-alert>
    <div>
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="$t('MainData')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <Video-tab-main
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
            </vs-tab>
            <vs-tab  v-if="$acl.check('Admin')" :label="$t('Comments')" icon-pack="feather" icon="icon-comment">
              <div class="tab-text">
                <video-comments
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
            </vs-tab>
            <vs-tab  v-if="$acl.check('Admin')" :label="$t('LiksUsers')" icon-pack="feather" icon="icon-check">
              <div class="tab-text">
                <video-likes
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
            </vs-tab>
            <vs-tab :label="$t('Rates')" icon-pack="feather" icon="icon-star">
              <div class="tab-text">
                <video-rates
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
            </vs-tab>
            <vs-tab  v-if="$acl.check('Admin')" :label="$t('Favorits')" icon-pack="feather" icon="icon-heart">
              <div class="tab-text">
                <video-favorit
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
          
            <vs-button
              class="mr-6"
              :disabled="!isFormValid"
              @click="submitData"
              v-if="$acl.check('Admin')"
              >{{ $t("Save") }}</vs-button
            >
          
            <vs-button
              type="border"
              color="danger"
              :to="{ name: 'Video' }"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoTabMain from "./VideoTabMain.vue";
 import VideoComments from "./VideoComments.vue";
 import VideoLikes from "./VideoLikes.vue";
 import VideoRates from "./VideoRates.vue";
 import VideoFavorit from "./VideoFavorit.vue";
import moduleVideo from "@/store/Video/moduleVideo.js";

export default {
  components: {
    VideoTabMain,
    VideoComments,
    VideoLikes,
    VideoRates,
    VideoFavorit
  },
  data() {
    return {
      Video_not_found: false,
      CanWrite: false,
      activeTab: 0,
 
      VideoModel: {
        VideoLikes : [],
      VideoComments : [],
      VideoRates : [],
      VideoViews:[],
      AppUserVideosFavorites:[],
      },
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.VideoModel.DescriptionEn && this.VideoModel.URL ;
    },
    
  },
  methods: {
    initValues() {
      this.VideoModel.VideoLikes = [];
      this.VideoModel.VideoComments = [];
      this.VideoModel.VideoRates = [];
      this.VideoModel.VideoViews=[];
      this.VideoModel.AppUserVideosFavorites=[];

    },

   
    submitData() {
      debugger;


      if (this.VideoModel.ID !== null && this.VideoModel.ID >= 0) {
        this.$vs.loading();
        this.$store
          .dispatch("VideoList/UpdateVideo", this.VideoModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess();
            this.$router.push({ name: "Video" });

            this.initVideoModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError();
          });
      } else {
        // add Video
        delete this.VideoModel.ID;
        this.VideoModel.popularity = 0;
        debugger;
        this.$vs.loading();
        this.$store
          .dispatch("VideoList/AddVideo", this.VideoModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess();
            this.$router.push({ name: "Video" });
            debugger;
            //  this.$store.state.courseList.search.pageNum=1;
            this.initVideoModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError();
          });
      }
      debugger;
    },
    initVideoModelValues() {
      this.VideoModel.VideoLikes = [];
      this.VideoModel.VideoComments = [];
      this.VideoModel.VideoRates = [];
      this.VideoModel.VideoViews=[];
      this.VideoModel.AppUserVideosFavorites=[];
    },
  },
  created() {
    debugger
    this.initValues();

    if (!moduleVideo.isRegistered) {
      this.$store.registerModule("VideoList", moduleVideo);
      moduleVideo.isRegistered = true;
    }
    this.initVideoModelValues();

    // var vm = this;
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("VideoList/GetVideo", ID).then((res) => {
        debugger;
        this.VideoModel = res.data.data;
       
      });
    }
  },
};
</script>
