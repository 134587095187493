<template>
  <div id="data-list-list-view">
   
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Likes')">
      
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="VideoModel.VideoLikes"
        >
          <template slot="thead">
            <vs-th> {{ $t("User") }}</vs-th>
     
            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.AppUser.ApplicationUser.FullName }}
                  </p>
                </vs-td>
             
                <vs-td class="whitespace-no-wrap">
                  <vs-button
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain } from "@/gloabelConstant.js";

// import moment from "moment";

export default {
  components: {
    // vSelect,
  },
  props: {
    VideoModel: {
      type: Object,
      required: true
    }
  },
  computed: {
   
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      isMounted: false,
      rowDataForDelete: {},
      VideoRoomModel: {},
      activePrompt: false,
   //   UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {
    
    
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },

    acceptAlert() {
      this.deleteVideoLike(this.rowDataForDelete);

      window.showDeleteSuccess();
    },
    deleteVideoLike(data) {
      if (data.ID > 0) {
        this.$store.dispatch("VideoList/DeleteVideoLike", data).then(()=>{
          const ItemIndex = this.VideoModel.VideoLikes.findIndex((p) => p.ID == data.ID)
          this.VideoModel.VideoLikes.splice(ItemIndex, 1)
        });
      }      
    }
  },
  created() {
   
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
